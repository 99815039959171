// @flow

import React from "react";
import { DefaultLayout, Layout, withPrivateRoute } from "@containers";
import { RegionalDistribution as _RegionalDistribution } from "@containers";

/**
 * RegionalDistribution
 */
const RegionalDistribution = (props: *) => (
    <Layout {...props}>
        <DefaultLayout title="Regioverdeling">
            <_RegionalDistribution />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(RegionalDistribution);
